import React from "react"
import "../../styles/app.scss"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/Layout/Layout"
import { Container, Section } from "bloomer"
import SEO from "../../components/SEO/SEO"
import StatementBlock from "../../components/Sanity/statementSerializer"

const AccessibilityStatement = () => {
  const data = useStaticQuery(graphql`
    {
      sanityAccessibilityStatement {
        _rawStatement
      }
    }
  `)
  return (
    <main>
      <Layout>
        <SEO title="Accessibility Statement" />
        <Section>
          <Container>
            <StatementBlock
              blocks={data.sanityAccessibilityStatement._rawStatement}
            />
          </Container>
        </Section>
      </Layout>
    </main>
  )
}
export default AccessibilityStatement
